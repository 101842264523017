<template>
  <div
    id="gallery"
    ref="gallery"
    class="grid auto-rows-fr gap-2"
    :style="columnStyle"
  >
    <a
      v-for="file in files"
      :key="file.id"
      :href="file.link"
      target="_blank"
      rel="noreferrer"
    >
      <preview :file="file" />
    </a>
    <upload
      @upload="upload"
      :busy="busy"
      :style="uploadStyle"
    />
  </div>
</template>

<script>
import moment from 'moment';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import 'photoswipe/dist/photoswipe.css';
import { formatFileSize, getFileType } from '@/helpers';

const _ = require('lodash');

const Preview = () => import('@/components/global/gallery/Preview.vue');
const Upload = () => import('@/components/global/gallery/Upload.vue');

export default {
  name: 'Gallery',
  components: {
    Preview,
    Upload,
  },
  props: {
    files: Array,
    busy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uploadStyle() {
      if (!this.files) {
        return '';
      }
      const colSpan = this.columns - (this.files.length % this.columns);
      return `grid-column: span ${colSpan} / span ${colSpan};`;
    },
    columnStyle() {
      return `grid-template-columns: repeat(${this.columns}, minmax(0, 1fr));`;
    },
  },
  data() {
    return {
      columns: 0,
      onResize: _.debounce(() => {
        this.calculateColumns();
      }, 100),
    };
  },
  methods: {
    getCurrentFile() {
      return this.files[this.lightbox.pswp.currIndex];
    },
    getFileDetail(file) {
      const details = [
        file.filename,
      ];
      const timestamp = file.mtime_ts === 0 ? file.upload_ts : file.mtime_ts;
      details.push(moment(timestamp * 1000).format('DD.MM.YY HH:mm:ss'));
      if (file.file_size > 0) {
        details.push(formatFileSize(file.file_size));
      }
      const detail = details.join('<span class="separator">|</span>');
      return detail;
    },
    upload(file) {
      this.$emit('upload', file);
    },
    calculateColumns() {
      const targetHeight = 220; // Same as Upload.vue
      const targetWidth = targetHeight * (3 / 4);
      const galleryWidth = this.$refs.gallery.offsetWidth;
      this.columns = Math.floor(galleryWidth / (targetWidth + 2));
    },
  },
  mounted() {
    this.calculateColumns();

    window.addEventListener('resize', this.onResize);

    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '#gallery',
        children: 'a',
        pswpModule: () => import('photoswipe'),
      });
      this.lightbox.on('uiRegister', () => {
        this.lightbox.pswp.ui.registerElement({
          name: 'file-details',
          order: 6,
          isButton: false,
          appendTo: 'root',
          html: 'File Details',
          onInit: (el, pswp) => {
            pswp.on('change', () => {
              const file = this.getCurrentFile();
              const detail = this.getFileDetail(file);
              el.innerHTML = detail;
            });
          },
        });

        this.lightbox.pswp.ui.registerElement({
          name: 'delete-button',
          title: 'Delete',
          order: 7,
          isButton: true,
          tagName: 'button',
          html: '<div><i class="fas fa-trash-alt"></i></div>',
          onInit: (el, pswp) => {
            el.addEventListener('click', () => {
              const file = this.getCurrentFile();
              if (!confirm(`Do you really wish to delete file '${file.filename}'?`)) {
                return;
              }
              this.$emit('delete', file);
              pswp.close();
            });
          },
        });

        this.lightbox.pswp.ui.registerElement({
          name: 'download-button',
          title: 'Download',
          order: 8,
          isButton: true,
          tagName: 'a',
          html: '<div><i class="fas fa-download"></i></div>',
          onInit: (el, pswp) => {
            el.setAttribute('download', '');
            el.setAttribute('target', '_blank');
            el.setAttribute('rel', 'noopener');
            pswp.on('change', () => {
              const file = this.getCurrentFile();
              el.href = file.link;
            });
          },
        });
      });
      this.lightbox.addFilter('itemData', (itemData, index) => {
        document.querySelectorAll('video').forEach((vid) => vid.pause());
        const file = this.files[index];
        const fileType = getFileType(file);
        if (fileType === 'generic') {
          return {
            html: `<a href="${file.link}" rel="noopener" target="_blank" class="btn btn-primary btn-sm text-nowrap flex gap-2 items-center"><i class="fas fa-download"></i> ${file.filename}</a>`,
          };
        }
        if (fileType === 'video') {
          return {
            html: `<video controls><source src="${file.link}">Your browser does not support the video tag.</video>`,
          };
        }
        return itemData;
      });
      this.lightbox.init();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);

    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
};
</script>

<style lang="scss">
  .pswp img {
    object-fit: scale-down;
    background-repeat: no-repeat;
  }

  .pswp__img--placeholder {
    display: none;
  }

  .pswp__zoom-wrap {
    transform: none !important;
  }

  .pswp__button {
    color: white;
    font-size: 20px;

    > div {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .pswp__button:hover, .pswp__button:active, .pswp__button:focus {
    color: #eee;
  }

  .pswp__content {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;

    video {
      height: 100%;
    }
  }

  .pswp__file-details {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    font-size: 14px;
    color: #fff;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    text-align: center;

    .separator {
      padding: 0 15px;
      color: #ccc;
    }
  }
</style>
